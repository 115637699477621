import { CompanyInfo, MenuResponse } from "@/types/types";
import countries from "@/data/google-countries.json";
import axios from "axios";

const API_BASE_URL = process.env.NEXT_PUBLIC_URL + "/api";

export async function fetchMenu(siteName: string) {
  try {
    const response = await axios.get(`${API_BASE_URL}/getMenu`, {
      params: { siteName },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching menu:", error);
    throw error;
  }
}

export async function fetchCategories(menu: {
  siteName: string;
  categoriesOrder?: string[];
}) {
  try {
    const response = await axios.get(`${API_BASE_URL}/getCategories`, {
      params: { siteName: menu.siteName },
    });

    const fetchedCategories = response.data;

    // Reorder categories based on categoriesOrder
    let orderedCategories = fetchedCategories;
    if (menu.categoriesOrder) {
      orderedCategories = menu.categoriesOrder
        .map((categoryId) =>
          fetchedCategories.find(
            (category: any) => category.categoryId === categoryId
          )
        )
        .filter((category) => category != null);
    }
    delete menu.categoriesOrder;

    return orderedCategories;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
}

// export async function fetchProducts(siteName: string, pageName: string) {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/getProducts`, {
//       params: { siteName, pageName },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching products:", error);
//     throw error;
//   }
// }

export async function fetchAds(menu_name: string, country: string) {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-native`, {
      params: { menu_name, country },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching menu:", error);
    throw error;
  }
}

export async function getMenus(
  industry: string,
  page: number = 1,
  limit: number = 100,
  country?: string,
  city?: string,
  cuisineType?: string
) {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  });

  // Optional query parameters
  if (country) params.append("country", country);
  if (city) params.append("city", city);
  if (cuisineType) params.append("cuisineType", cuisineType);

  try {
    const response = await axios.get(`${API_BASE_URL}/getMenus`, {
      params: params,
    });

    return response?.data as MenuResponse;
  } catch (error) {
    console.error("Error fetching menu:", error);
    throw error;
  }
}

export async function getSiteMapMenus() {
  try {
    const response = await axios.get(`${API_BASE_URL}/getSitemapMenus`);
    return response?.data as MenuResponse;
  } catch (error) {
    console.error("Error fetching menu:", error);
    throw error;
  }
}

export const getCountryName = (countryCode: string) => {
  const country = countries.find((c: any) => c.code === countryCode);
  return country ? country.name : "";
};

export const getDisplayAddress = (companyInfo: CompanyInfo): string => {
  if (!companyInfo) {
    return "";
  }
  let address = "";
  if (companyInfo.address) {
    address += companyInfo.address.trim() + ", ";
  }
  if (companyInfo.city) {
    address += companyInfo.city.trim() + ", ";
  }
  if (companyInfo.zipCode) {
    address += companyInfo.zipCode.trim() + ", ";
  }
  if (companyInfo.state) {
    address += companyInfo.state.trim() + ", ";
  }
  if (companyInfo.country) {
    address += getCountryName(companyInfo?.country || "");
  }
  return address;
};

export const getMapUrl = (companyInfo: CompanyInfo): string => {
  if (!companyInfo) {
    return "";
  }
  let mapUrl = "";
  if (!companyInfo.mapUrl) {
    mapUrl += `https://www.google.com/maps/place/`;
    mapUrl += companyInfo.address
      ? `+${companyInfo.address.trim().replace(/ /g, "+")}`
      : "";
    mapUrl += companyInfo.city
      ? `+${companyInfo.city.trim().replace(/ /g, "+")}`
      : "";
    mapUrl += companyInfo.country
      ? `+${companyInfo.country.replace(/ /g, "+")}`
      : "";
  } else {
    mapUrl += `${!companyInfo.mapUrl.includes("http") ? "https://" : ""}${
      companyInfo.mapUrl
    }`;
  }
  return mapUrl;
};
